import React from "react";
import Photos from "../Photos";

function Bio() {
  return (
    <div className="secondaryWrapper">
      <div className="leftSide">
        <h1 className="neonText">Bio</h1>
        <div className="bigP bio">
          <p>
            Juan Sebastián Vassallo is an Argentinian composer based in Bergen,
            Norway. He holds a master’s degree in music perception and cognition
            and is currently pursuing his Ph.D. in Artistic Research at the
            University of Bergen. His current artistic research aims to explore
            possibilities for human-computer interaction in art creation,
            encompassing points of intersection between computer-assisted
            composition, artificial intelligence, algorithmic poetry, generative
            visuals, and live electronics. His creative practice is
            characterized by collaborative and interdisciplinary works that
            explore interaction between traditional and contemporary musical
            means and real-time processing of sound, coupled with instrumental
            composition and improvisation. Parallel to this, he has developed an
            intense activity as a Tango pianist and arranger, performing in some
            of the most important venues of Europe and Latin America.
          </p>
        </div>
      </div>
      <Photos />
    </div>
  );
}

export default Bio;
