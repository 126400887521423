import React from "react";
import Composition from "../Composition";
import Composition_audio from "../Composition_audio";

function Compositions() {
  return (
    <div className="secondaryWrapper">
      <div className="width">
        <div>
          <h1 className="neonText">Live recordings</h1>
          <h3 className="subtitle2">Musical works</h3>
        </div>

        <div className="projects">
          <Composition
            title="'Versificator - Render 3'"
            vid="MGxBEbUMMt4"
            text="Vokalensembelet 'Tabula Rasa'"
          />

          <Composition
            title="'Oscillations (i)'"
            vid="OWPVtlxfByM"
            text="Julie Hasfjord (voice), Andrea Toft (visuals)"
          />
          <Composition
            title="'Oscillations (iii)'"
            vid="WDN36C64WTc"
            text="Késia Decoté / Anders Hannevold (piano), Alexander Fiske-Fosse (narrator), Sergej Tchirkov (accordion), Andrea Toft (visuals)"
          />
          <Composition
            title="'Evolutions 2A'"
            vid="5h_lDeV5iOc"
            text="Langhaugen String Orchestra"
          />
          <Composition
            title="'I am a strange loop'"
            vid="qf292OZFaaY"
            text="JÓR Saxophone quartet, Sergej Tchirkov (accordion), Anders Hannevold (organetto)"
          />
          <Composition
            title="'Aphorisms (2)'"
            vid="E-B-HRO5MYs"
            text="Ensemble Obsidiane - Mari Bølgen Halvorsen (violin), Mara Haugen (violin), Ingrid Eriksen (viola), Matias Monsen (cello)"
          />
          <Composition
            title="'Milonga del gesto'"
            vid="cBkxHk5SSWI"
            text="For solo guitar  - Benjamin Sørensen (guitar)"
          />
          <Composition
            title="'Mirrors' (based on the poem 'Cazador de ángeles' by Luis Ressia)"
            vid="tWAQtFqOht0"
            text=" "
          />
          <Composition
            title="'Trivium Jazz Cuyano'"
            vid="2Sl64le8r84"
            text="Federico Cámara-Halac (piano), Javier Mazzuccelli (percussion), Mauricio Diez (double bass"
          />
          <Composition
            title="'RGB'"
            vid="XVgRveZZRLc"
            text="Mauricio Pregot (violin), Florencia Veronese (violin), Flor Maria Díaz (viola), Marcela Vicente (cello)"
          />
        </div>

        <div className="projects">
          <div>
            <Composition_audio
              title="'Trivium Jazz Cuyano'"
              audio="music/Trivium_audio.mp3"
              text="Zone Experimental Basel"
            />
          </div>
          <div>
            <Composition_audio
              title="'Elevator pitch'"
              audio="music/Elevator-Pitch_Juan-Vassallo.mp3"
              text="Lucas Fels (cello)"
            />
          </div>
        </div>
        <div>
          <h3 className="subtitle2">Audiovisual works</h3>
        </div>

        <div className="projects">
          <Composition
            title="'ARGO/AGO'"
            vid="nj7lkkAAGIs"
            text="Immersive sound and visuals (collaborative work)"
          />
          <Composition
            title="'Deconstructions'"
            vid="KwPAwxJZcK0"
            text="Jessica Wagner (voice)"
          />

          <Composition
            title="'Bokeh'"
            vid="UcvPKtGPTJ4"
            text="Christmas calendar 2020 Grieg Academy of Music, University of Bergen"
          />
        </div>

        <div>
          <h3 className="subtitle2">Multimedia Installations</h3>
        </div>
        <div className="projects">
          <Composition
            title="'Encroach'"
            vid="0Gs0bcSGbH4"
            text="Lilianne Milgroom (visuals), Juan Vassallo (soundscapes)"
          />
          <Composition
            title="'Sonidos de lo incierto'"
            vid="yBBQS4i0N1k"
            text="Fernanda Almanza, Federico Echave, Juan Vassallo"
          />
        </div>
        {/* <h3 className="neonText2">Complete catalog of works</h3>
        <ul className="cv">
          <li>
            2022 – "I am a strange loop" for saxophone quartet, harpsichord and
            recorder
          </li>
          <li>2023 – "Oscillations (I)" for voice and electronics </li>
          <li>2022 – "Evoluciones 2A" for string orchestra </li>
          <li>2022 - "Isovell Che Segila Centelare" for eight voices</li>
          <li>2021 – "Elevator pitch" for trumpet and electronics</li>
          <li>2021 - "Versificator - Render 3" for five voices</li>
          <li>2021 – "Milonga del gesto" for solo guitar</li>
          <li>2020 – "Both ways" for string quartet</li>
          <li>
            2019 – "Deconstructions" for voice, synthetized voice and visuals
          </li>
          <li>2018 – "Mirrors (electroacoustic)</li>
          <li>2018 – "Viderunt omnes" (electroacoustic)</li>
          <li>2017 – "Romance del niño del agua" for SATB choir</li>
          <li>2017 – "Sonidos de lo Incierto" (multimedia installation)</li>
          <li>
            2016 – "Música para la lectura de tres poemas de Julio Cortázar" for
            vocal ensamble
          </li>
          <li>2015 – "Semblanza" (mixed media)</li>
          <li>2015 - "Encroach" (sound installation)</li>
          <li>2014 – "Retro-alimento" (sound installation)</li>
          <li>2014 – "Cinco Soles" (mixed media) </li>
          <li>
            2013 – Trilogy: "To make a blindfold - Measure - The size of your
            head" (mixed media)
          </li>
          <li>2012 – "RGB" for string quartet</li>
          <li>2012 – "Yzur" for voice and piano</li>
          <li>
            2012 – "Trivium – Jazz cuyano" for piano, double bass and percussion
          </li>
          <li>2011 – "Non-Sancta Romana" for string orchestra </li>
          <li>2010 – Quintet for harpsichord and string quartet</li>
          <li>
            2006 – "Una plegaria americana" for flute, violin, cello and piano
          </li>
          <li>2005 – "Diego de la gente" (electroacoustic) </li>
          <li>2005 – "Katrina" (wind quartet)</li>
          <li>2004 – "Música para cine clase B" for piano and cello</li>
        </ul> */}
      </div>
    </div>
  );
}

export default Compositions;
